<template>
  <b-container fluid class="text-editor p-0">

    <!-- Menu bar -->
    <editor-menu-bar v-if="editable"
                     v-slot="{ commands, isActive, focused }"
                     class="menu-bar"
                     :editor="editor">
      <div class="menu-bar-buttons is-hidden" :class="{ 'is-visible': focused }">

        <div class="menu-bar-buttons-main">

          <!-- Bold -->
          <b-button
              type="button"
              variant="outline-secondary"
              size="sm"
              class="px-2 mr-1"
              :pressed.sync="isActive.bold()"
              @click="commands.bold">
            <font-awesome-icon icon="bold" size="lg" />
          </b-button>

          <!-- Italic -->
          <b-button
              type="button"
              variant="outline-secondary"
              size="sm"
              class="px-2 mr-1"
              :pressed.sync="isActive.italic()"
              @click="commands.italic">
            <font-awesome-icon icon="italic" size="lg" />
          </b-button>

          <!-- Underline -->
          <b-button
              type="button"
              variant="outline-secondary"
              size="sm"
              class="px-2 mr-1"
              :pressed.sync="isActive.underline()"
              @click="commands.underline">
            <font-awesome-icon icon="underline" size="lg" />
          </b-button>

          <!-- Strikethrough -->
          <b-button
              type="button"
              variant="outline-secondary"
              size="sm"
              class="px-2 mr-1"
              :pressed.sync="isActive.strike()"
              @click="commands.strike">
            <font-awesome-icon icon="strikethrough" size="lg" />
          </b-button>

          <!-- Unordered list -->
          <b-button
              type="button"
              variant="outline-secondary"
              size="sm"
              class="px-2 mr-1"
              :pressed.sync="isActive.bullet_list()"
              @click="commands.bullet_list">
            <font-awesome-icon icon="list-ul" size="lg" />
          </b-button>

          <!-- Ordered list -->
          <b-button
              type="button"
              variant="outline-secondary"
              size="sm"
              class="px-2 mr-1"
              :pressed.sync="isActive.ordered_list()"
              @click="commands.ordered_list">
            <font-awesome-icon icon="list-ol" size="lg" />
          </b-button>

          <!-- Horizontal rule -->
          <b-button
              type="button"
              variant="outline-secondary"
              size="sm"
              class="px-2 mr-1"
              @click="commands.horizontal_rule">
            <font-awesome-icon icon="grip-lines" size="lg" />
          </b-button>

          <!-- Undo -->
          <b-button
              type="button"
              variant="outline-secondary"
              size="sm"
              class="px-2 mr-1"
              @click="commands.undo">
            <font-awesome-icon icon="undo" size="lg" />
          </b-button>

          <!-- Redo -->
          <b-button
              type="button"
              variant="outline-secondary"
              size="sm"
              class="px-2 m-0"
              @click="commands.redo">
            <font-awesome-icon icon="redo" size="lg" />
          </b-button>

        </div>

      </div>

    </editor-menu-bar>

    <!-- Editor -->
    <b-row>

      <b-col class="editor-wrapper">
        <span v-if="label" class="editor-label">{{ label }}</span>
        <editor-content class="editor" :editor="editor" :style="{ 'height': `${minHeight}px` }" />
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { Editor, EditorMenuBar, EditorContent } from 'tiptap';
import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  CodeBlock,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Italic,
  Link,
  ListItem,
  Underline,
  OrderedList,
  Strike,
} from 'tiptap-extensions';

export default {
  name: 'TextEditor',

  components: {
    EditorMenuBar,
    EditorContent,
  },

  props: {
    editable: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    outputFormat: {
      type: String,
      default: 'html'
    },
    minHeight: {
      type: String,
      default: '120'
    }
  },

  data () {
    return {
      editor: null,
    };
  },

  watch: {
    editable () {
      this.editor.setOptions({ editable: this.editable });
    }
  },

  created () {
    this._initComponent();
  },

  beforeDestroy () {
    this.editor.destroy();
  },

  methods: {
    // ================================================================================
    // Helpers
    // ================================================================================

    _initComponent () {
      this.editor = new Editor({
        editable: this.editable,
        content: this.content,
        extensions: [
          new Blockquote(),
          new Bold(),
          new BulletList(),
          new Code(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3, 4, 5, 6] }),
          new History(),
          new HorizontalRule(),
          new Italic(),
          new Link(),
          new ListItem(),
          new Underline(),
          new OrderedList(),
          new Strike(),
        ],
        onUpdate: ({ getHTML, getJSON }) => {
          this.$emit('onUpdate', this.outputFormat === 'html' ? getHTML() : getJSON());
        }
      });
    }

  }
};
</script>

<style lang="scss">
.text-editor {
  .menu-bar-buttons {
    &.is-hidden {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.2s, opacity 0.2s;
    }

    &.is-visible {
      visibility: visible;
      opacity: 1;
      transition: visibility 0.2s, opacity 0.2s;
    }

    b-button {
    	background: transparent;
    	border: 0;
    }
  }

  .editor-wrapper {
    position: relative;

    .editor-label {
      position: absolute;
      top: -8px;
      left: 24px;
      padding: 0 5px;
      background: white;
      z-index: 2;
      font-weight: 500;
      font-size: 0.7rem;
      color:red;
    }

    .editor {
      background-color: transparent;

      .ProseMirror {
        padding: 0.75rem;
        border: 1px solid #dadce0;
        border-radius: 4px;
        height: 100%;

        &.ProseMirror-focused {
          outline: none;
        }

        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;

        .node-has-focus {
          border-radius: 2px;
          box-shadow: 0 0 0 3px #dadce0;
        }

        blockquote {
          border-left: 3px solid rgba(black, 0.1);
          color: rgba(black, 0.8);
          padding-left: 0.8rem;
          font-style: italic;

          p {
            margin: 0;
          }
        }

        p code {
          padding: 0.2rem 0.4rem;
          border-radius: 5px;
          font-size: 0.8rem;
          font-weight: bold;
          background: rgba(black, 0.1);
          color: rgba(black, 0.8);
        }

        pre {
          padding: 0.7rem 1rem;
          border-radius: 5px;
          background: black;
          color: white;
          font-size: 0.8rem;
          overflow-x: auto;

          code {
            display: block;
          }
        }

        ul,
        ol {
          padding-left: 1rem;
        }

        li > p,
        li > ol,
        li > ul {
          margin: 0;
        }

        table {
          border-collapse: collapse;
          table-layout: fixed;
          width: 100%;
          margin: 0;
          overflow: hidden;

          td, th {
            min-width: 1em;
            border: 2px solid #ddd;
            padding: 0;
            vertical-align: top;
            box-sizing: border-box;
            position: relative;
            > * {
              margin: 0;
              padding: 3px 5px;
              background: white;
            }
          }

          th {
            font-weight: bold;
            text-align: left;
          }

          .selectedCell:after {
            z-index: 2;
            position: absolute;
            content: "";
            left: 0; right: 0; top: 0; bottom: 0;
            background: rgba(gray, 0.4);
            pointer-events: none;
          }

          .column-resize-handle {
            position: absolute;
            right: -2px; top: 0; bottom: 0;
            width: 2px;
            z-index: 20;
            background-color: red;
            cursor: col-resize !important;
          }
        }

        .tableWrapper {
          margin: 1em 0;
          overflow-x: auto;
        }

        .resize-cursor {
          cursor: col-resize;
        }
      }
    }

  }
}
</style>
