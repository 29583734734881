<template>
  <div id="tutor-talk-page" class="position-relative">
    <div class="tutor-talk-header page-header row w-100 align-items-end mx-0 mb-4">
      <div class="title col-12 col-md-7 pb-2 mx-auto">
        <h3 class="text-white">{{ $t("telestia.title.tutor_talk") }}</h3>
      </div>
    </div>

    <div class="row mx-0">
      <div class="col-12 col-md-7 mx-auto">
        <h5>{{ $t("telestia.title.send_message") }}</h5>

        <!-- Sending message loading state -->
        <div v-if="pendingMessageSending" class="loading-state d-flex justify-content-center my-5">
          <b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="primary" />
        </div>

        <!-- Sending message form -->
        <template v-else>
          <!-- Message subject input -->
          <input type="text" :placeholder="$t('telestia.title.subject')" class="form-control mb-1" v-model="newMessage.subject" maxlength="200">

          <!-- Main message textarea TODO: change to rich text editor -->
          <text-editor class="mb-3" :content="newMessage.body" :min-height="'300'" @onUpdate="newMessage.body = $event" />

          <!-- Upload attachments button and guidelines -->
          <div class="d-flex flex-column align-items-start mb-2">
            <input
                type="file"
                name="assisgnment"
                id="attachment-input"
                class="d-none"
                accept=".jpg, .jpeg, .png, .pdf, .tcd"
                @change="addAttachment"
                multiple
            >
            <button class="file-upload primary-btn px-2 mb-2" @click="fireFileInput">{{ $t("telestia.button.upload_attachment") }}</button>
            <div class="d-flex flex-column align-items-start justify-content-start">
              <span v-b-modal.guidelines-modal class="text-link mouse-pointer"><u>{{ $t("telestia.title.attachment_guidelines") }}</u></span>
              <span>{{ $t("telestia.title.accepted_types") }}: <b>.jpg, .png, .pdf, .tcd</b></span>
              <span>{{ $t("telestia.title.maximum_size") }}: <b>2MB</b></span>
            </div>
          </div>

          <!-- Attachments container -->
          <div v-if="newMessage.attachments.length > 0" class="attachments-container row w-100 mx-0 mb-4 border rounded">
            <div class="col text-nowrap p-2 mr-3" v-for="(attachment, index) in newMessage.attachments" :key="`${attachment.name}-${index}`">
              <span class="mr-1">{{ attachment.name }}</span>
              <font-awesome-icon class="text-blue mouse-pointer" :icon="['fas', 'times-circle']" size="sm" @click="removeAttachment(attachment)"></font-awesome-icon>
            </div>
          </div>

          <!-- Send button and conversations note -->
          <div style="text-align: end;">
            <button class="primary-btn px-2 py-1 mb-2 ml-auto" @click="sendMessage" :disabled="!canSendMessage" :class="{ disabled: !canSendMessage }">
              {{ $t("telestia.button.send") }}
            </button>
            <p>
              <small>
                * {{ $t("telestia.message.tutor_talk_note") }}
              </small>
            </p>
          </div>
        </template>

        <!-- Conversations loading state -->
				<div v-if="pendingComponentInit" class="loading-state d-flex justify-content-center my-5">
				  <b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="primary" />
				</div>

				<!-- Conversations loaded state -->
        <template v-else-if="!pendingComponentInit && conversations.length > 0">
	        <h5 class="mt-5">{{ $t("telestia.title.conversations") }}</h5>

	        <div v-for="(message, mIndex) in conversations" :key="`message-${mIndex}`" :class="{ 'mb-5': mIndex === conversations.length - 1 }">
			      <div
			      	class="message-header d-flex align-items-center p-2 mouse-pointer"
              v-b-toggle="`message-${mIndex}`"
			      	:style="{ borderLeft: `2px solid ${getColorBasedOnSender(message.type)}` }"
			      >
			      	<div class="d-flex flex-column">
	              <span>{{ message.subject }}</span>
	              <div class="d-flex align-items-center">
			            <span class="px-1 mr-2" :style="{ borderRadius: '3px', backgroundColor: getColorBasedOnSender(message.type) }">
					          <font-awesome-icon
					          	:icon="['fas', message.type === 2 ? 'long-arrow-alt-down' : 'long-arrow-alt-up']"
					          	style="color: white;"
					          />
					        </span>
			            From {{ getSender(message.type) }}
			          </div>
	            </div>
	            <span class="ml-auto">{{ message.date | formatDate }}</span>
			      </div>

						<b-collapse :id="`message-${mIndex}`" @shown="fetchMessage(message.id, mIndex)" class="message-body">
							<b-card>
								<div class="selectable" v-html="message.body"></div>

								<template v-if="message.attachments && message.attachments.length > 0">
									<b-icon v-if="message.loadingAttachments" icon="three-dots" animation="fade" font-scale="2"></b-icon>

			            <div v-else class="sticky-bottom mt-5">
			            	<span>{{ $t("telestia.title.attachments") }}</span>
			              <div class="border p-1">
			              	<p v-for="(attachment, aIndex) in message.attachments" :key="`attachment-${aIndex}`" class="mb-0">
			              		<font-awesome-icon :icon="['fas', 'paperclip']" class="text-blue mr-2" />

			              		<a v-if="internalConversations" class="mr-2" :href="createHref(attachment.blob)" :download="attachment.name" >{{ attachment.name }}</a>
			              		<a v-else class="mr-2" :href="attachment.downloadURL" :download="attachment.file" >{{ attachment.file }}</a>
			              	</p>
			              </div>
			            </div>
	              </template>
							</b-card>
						</b-collapse>
					</div>
				</template>

      </div>
    </div>

    <b-modal id="guidelines-modal" centered scrollable hide-header hide-footer size="lg" hide-header-close>
      <template>
		    <div class="d-flex align-items-center">
		      <h4 class="mb-0">{{ $t('telestia.title.photo_guidelines') }}</h4>
		      <font-awesome-icon
		        :icon="['fas', 'times']"
		        class="ml-auto mouse-pointer"
		        @click="$bvModal.hide('guidelines-modal')"
		      />
		    </div>
		    <hr>
		    <div class="d-block" v-html="translatedGuidelines"></div>
		  </template>
    </b-modal>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import TextEditor from '../shared/TextEditor/TextEditor.vue';
import uploadGuidelines from '../shared/uploadGuidelines.js';

export default {
  name: "tutor-talk",

  components: { TextEditor },

  created() { this.fetchConversations(); console.log(uploadGuidelines) },

  data() {
    return {
      pendingComponentInit: false,
      pendingMessageSending: false,

      newMessage: {
        subject: '',
        body: '',
        attachments: []
      },
			attachmentFailures: [],
      failuresAlertDismissCountdown: 0,

      attachmentGuidelines: uploadGuidelines,
    }
  },

  computed: {
    conversations() {
      return orderBy(this.$store.getters["userMessages/getConversations"], ['date'], ['desc']);
    },

    internalConversations() { return this.$store.getters['userMessages/internalConversations']; },

    canSendMessage() { return  this.newMessage.subject.trim() && this.newMessage.body.trim() },

    // Assignment upload guidlines based on selected language if present
    translatedGuidelines() {
      return this.attachmentGuidelines[this.$store.getters['user/getSelectedLanguage']]
          ? this.attachmentGuidelines[this.$store.getters['user/getSelectedLanguage']]
          : this.attachmentGuidelines.en;
    }
  },

  methods: {
    // Fetch conversations
    async fetchConversations() {
      try {
        this.pendingComponentInit = true;

        await this.$store.dispatch("userMessages/fetchConversations");
      } catch (e) {
				console.log(e);
        this.showAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.conversations') + "\n" + this.$t('telestia.error.connection'),
          icon: 'error',
        });
      } finally {
        this.pendingComponentInit = false;
      }
    },

    // Fetch separate message
    async fetchMessage(messageId, messageIndex) {
    	if (!this.internalConversations) { return; }

      if (this.conversations[messageIndex].attachmentsFetched) { return; }

      try {
        this.$store.dispatch("userMessages/setMessageAttachmentsLoadingState", { messageId, state: true });

        await this.$store.dispatch("userMessages/fetchMessage", messageId);
      } catch (e) {
        console.log(e);
        this.showAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.attachments') + "\n" + this.$t('telestia.error.connection'),
          icon: 'error',
        });
      } finally {
      	this.$store.dispatch("userMessages/setMessageAttachmentsLoadingState", { messageId, state: false });
      }
    },

    // ligthblue if message sent by a user, lightgreen if message sent by tutor
    getColorBasedOnSender(messageType) { return messageType === 2 ? 'lightskyblue' : 'lightgreen' },

    getSender(messageType) { return messageType === 2 ? 'me' : 'Tutor' },

    createHref(blob) { return URL.createObjectURL(blob); } ,

    // Add attchment file to a message if not bugger that 2MB each
    addAttachment(e) {
      const filesToUpload = e.target.files;
	  	for (let i = 0; i < filesToUpload.length; i++) {
	  		if (Math.round((filesToUpload[i].size / 1024)) > 2048) {
          this.attachmentFailures.push(filesToUpload[i].name);
          continue;
        }
        this.newMessage.attachments.push(filesToUpload[i])
	  	}

      if (this.attachmentFailures.length > 0) {
        this.$swal({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.attachment_too_big'),
          timer: 5000,
          button: true,
        }).then(() => this.attachmentFailures = []);
      }
    },

    // Remove an attachment file
		removeAttachment(attachment) { this.newMessage.attachments = this.newMessage.attachments.filter(a => a !== attachment); },

    // Send message
    async sendMessage() {
      try {
        this.pendingMessageSending = true;

        const messageFormData = new FormData();
        messageFormData.append('subject', this.newMessage.subject);
        messageFormData.append('body', this.newMessage.body);
        this.newMessage.attachments.forEach(attachment => {
          messageFormData.append('attachment', attachment);
        })

        await this.$store.dispatch("userMessages/sendMessage", messageFormData);

        this.clearMessageForm();
        this.showAlert({
          title: this.$t('telestia.tutor_talk.sent'),
          text: '',
          icon: 'success'
        });
      } catch (e) {
        console.log(e);
        this.showAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.send_msg') + "\n" + this.$t('telestia.error.connection'),
          icon: 'error',
        });
      } finally {
        this.pendingMessageSending = false;
      }
    },

    // Clear message form after message is successfully sent
    clearMessageForm() {
      this.newMessage.subject = '';
      this.newMessage.body = '';
      this.newMessage.attachments = [];
    },

    fireFileInput() {
      document.getElementById('attachment-input').click();
    },

    showAlert({ title, text, icon }) {
    	this.$swal({ title, text, icon, timer: 5000, button: true });
    }
  }
}
</script>

<style scoped>
.text-link:hover {
  color: #1b96cf;
}
.tutor-talk-header {
  background-image: url(../../assets/images/page-header/tutor-talk.jpg);
}

.message-header {
	border: 1px solid rgba(0, 0, 0, 0.125);
	background-color: #F2F2F2;
}
.message-header:focus, .message-header:active {
  outline: none;
}

.message-body {
  min-height: 30px;
  max-height: 400px;
  overflow: auto;
}

.sticky-bottom {
  position: sticky;
  bottom: 10px;
  background: white;
}
</style>
